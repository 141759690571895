import type { UserDecathlon } from 'src/components/account/types'
import { useContext, useState } from 'react'
import axios from 'axios'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import type { SubmitHandler } from 'react-hook-form'
import { Input as InputCommon, Select } from 'src/components/common/Input'
import { AccountContext } from 'src/components/account/context'
import Input from 'src/components/ui/Input'
import { useToastContext } from 'src/components/ui/ToastMessage/ToastContext'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import CheckSaveIcon from '../../../../images/icons/CheckSaveIcon'
import ClipEditIcon from '../../../../images/icons/ClipEditIcon'
import { PersonalFavoriteStore } from './PersonalFavoriteStore'
import { PersonalFavoriteSport } from './PersonalFavoriteSport'

import './styles.scss'

interface PersonalDataProps {
  userDecathlon: UserDecathlon
}

const schema = z.object({
  name: z.string(),
  lastname: z.string().optional(),
  gender: z.string().optional(),
  email: z.string(),
  mobile: z
    .string()
    .regex(/^\+\d{2} \(\d{2}\) 9 \d{4}-\d{4}$/, 'Número de telefone inválido.')
    .optional(),
  dateBirth: z
    .string()
    .regex(
      /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
      'Data de nascimento inválida.'
    )
    .optional(),
  document: z
    .string()
    .regex(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'O documento deve ter 11 caracteres')
    .optional(),
})

type SchemaType = z.infer<typeof schema>

const formatDate = (date: string) => {
  const [year, month, day] = date.split('-')

  return `${day}/${month}/${year}`
}

const formatPhoneNumber = (phoneNumberString: string): string | undefined => {
  const cleaned = phoneNumberString.replace(/\D/g, '')
  const match = cleaned.match(/^(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})$/)

  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]} ${match[4]}-${match[5]}`
  }

  return undefined
}

export const PersonalData = ({ userDecathlon }: PersonalDataProps) => {
  const { updateUserDecathlon } = useContext(AccountContext)
  const { sendToast } = useToastContext()
  const [inputEdit, setInputEdit] = useState(false)
  const email = userDecathlon?.getUserProfile.claims.email
  const phoneMobile = userDecathlon?.getUserProfile.claims.phone_number

  const selectOptions = [
    { value: '', label: 'Selecione' },
    { value: 'male', label: 'Masculino' },
    { value: 'female', label: 'Feminino' },
    { value: 'not_known', label: 'Outros' },
  ]

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: userDecathlon?.getUserProfile.claims.given_name,
      lastname: userDecathlon?.getUserProfile.claims.family_name,
      dateBirth: userDecathlon?.getUserProfile.claims.birthdate
        ? formatDate(userDecathlon?.getUserProfile.claims.birthdate)
        : undefined,
      gender: userDecathlon?.getUserProfile.claims.gender,
      email,
      document: userDecathlon?.getUserProfile.additional_information
        ? userDecathlon?.getUserProfile.additional_information[0].value.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            '$1.$2.$3-$4'
          )
        : undefined,
      mobile: formatPhoneNumber(phoneMobile),
    },
  })

  const onSubmit: SubmitHandler<SchemaType> = async (info) => {
    try {
      await schema.parseAsync(info)

      const [day, month, year] = info.dateBirth
        ? info.dateBirth?.split('/')
        : []

      const birthDateFormat = info.dateBirth ? `${year}-${month}-${day}` : ''

      const parsedInfo = {
        given_name: info.name,
        family_name: info.lastname,
        email: userDecathlon?.getUserProfile.claims.email,
        gender: info.gender,
        birthdate: birthDateFormat,
        phone_number: info?.mobile
          ?.replace(' ', '')
          ?.replace('-', '')
          ?.replace('(', '')
          ?.replace(')', ''),
        cpf: info.document
          ? info.document.replace('.', '').replace('-', '')
          : null,
      }

      const response = await axios.post('/api/account/updateUserProfile', {
        data: parsedInfo,
      })

      if (response.status === 200) {
        updateUserDecathlon()

        const text = 'Alterações salvas'
        const variant = 'success'

        sendToast({
          text,
          variant,
        })

        setInputEdit(false)
      }
    } catch {
      const text = 'Erro ao salvar os dados, tente novamente mais tarde'
      const variant = 'error'

      sendToast({
        text,
        variant,
      })
    }
  }

  function trackEvent() {
    const event = makeEventTrack({
      eventAction: 'Dados pessoais - Editar informações',
      eventPage: 'my account - Gerenciar perfil',
    })

    sendEvent(event)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="">
      <div className="mt-16 border-b border-[#E1E4E7] w-full pb-3 items-center mb-8">
        {inputEdit ? (
          <button className="flex items-center gap-2 text-primaryBlue font-inter font-semibold text-sm leading-6">
            <p className="relative  ">Salvar alterações</p>
            <CheckSaveIcon />
          </button>
        ) : (
          <span
            onClick={() => {
              setInputEdit(true)
              trackEvent()
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setInputEdit(true)
                trackEvent()
              }
            }}
            role="button"
            tabIndex={0}
            className="flex items-center gap-2 text-primaryBlue font-inter font-semibold text-sm leading-6"
          >
            <p className="relative  ">Editar informações</p>
            <ClipEditIcon />
          </span>
        )}
      </div>

      <div className="flex items-start flex-col sm:flex-row sm: w-full justify-between xs:justify-around gap-5 sm:gap-10 mb-10 xs:mb-8">
        <InputCommon
          classContainer="w-full"
          className={`data-input w-full border-neutral04 border-[1px] py-5 px-4 ${
            inputEdit && 'hover:border-[#a4adb7]'
          } mb-4 sm:mb-0 font-inter text-base font-normal`}
          label="Primeiro Nome"
          name="name"
          disabled={!inputEdit}
          mask=""
          register={register}
          errors={errors.name}
        />
        <InputCommon
          classContainer="w-full"
          className={`data-input w-full border-neutral04 border-[1px] py-5 px-4 ${
            inputEdit && 'hover:border-[#a4adb7]'
          } mb-4 sm:mb-0 font-inter text-base font-normal`}
          label="Sobrenome"
          name="lastname"
          disabled={!inputEdit}
          mask=""
          register={register}
          errors={errors.lastname}
        />
      </div>
      <div className="flex items-start flex-col sm:flex-row sm: w-full justify-between xs:justify-around gap-5 sm:gap-10 mb-10">
        <Input
          classContainer="w-full"
          className={`data-input w-full border-neutral04 border-[1px] py-5 px-4 ${
            inputEdit && 'hover:border-[#a4adb7]'
          } mb-4 sm:mb-0 font-inter text-base font-normal`}
          label="Nascimento"
          name="dateBirth"
          defaultValue={
            userDecathlon?.getUserProfile.claims.birthdate
              ? formatDate(userDecathlon?.getUserProfile.claims.birthdate)
              : undefined
          }
          mask="99/99/9999"
          disabled={!inputEdit}
          errors={errors.dateBirth}
          register={register}
        />

        <Select
          name="gender"
          label="Gênero"
          defaultValue={userDecathlon?.getUserProfile.claims.gender}
          classContainer="w-full"
          disabled={!inputEdit}
          register={register}
          errors={errors.gender}
        >
          {selectOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </div>
      <div className="flex items-start flex-col sm:flex-row sm: w-full justify-between xs:justify-around gap-5 sm:gap-10 mb-10">
        <Input
          classContainer={`w-full ${inputEdit ? 'text-inputDisabled' : ''} `}
          className="data-input w-full border-neutral04 border-[1px]  py-5 px-4 mb-4 sm:mb-0 font-inter text-base font-normal"
          label="E-mail:"
          name="email"
          value={email}
          disabled
          errors={errors.email}
          register={register}
        />
        <Input
          classContainer={`w-full ${inputEdit ? 'text-inputDisabled' : ''} `}
          className="data-input w-full border-neutral04 border-[1px] py-5 px-4 mb-4 sm:mb-0 font-inter text-base font-normal"
          label="CPF"
          name="document"
          placeHolder=""
          defaultValue={
            userDecathlon?.getUserProfile.additional_information
              ? userDecathlon?.getUserProfile.additional_information[0].value.replace(
                  /(\d{3})(\d{3})(\d{3})(\d{2})/,
                  '$1.$2.$3-$4'
                )
              : undefined
          }
          mask="999.999.999-99"
          disabled
          errors={errors.document}
          register={register}
        />
      </div>
      <div className="flex sm:flex-col sm:flex-row w-full xs:justify-around pb-10 border-b border-[#E1E4E7] mb-10">
        <Input
          classContainer="w-full sm:pr-[20px]"
          className={`data-input w-full border-neutral04 border-[1px] py-5 px-4 ${
            inputEdit && 'hover:border-[#a4adb7]'
          } mb-4 sm:mb-0 font-inter text-base font-normal`}
          label="Telefone de contato"
          name="mobile"
          placeHolder={formatPhoneNumber(phoneMobile)}
          defaultValue={formatPhoneNumber(phoneMobile)}
          mask="+55 (99) 9 9999-9999"
          disabled={!inputEdit}
          errors={errors.mobile}
          register={register}
        />
      </div>

      <PersonalFavoriteStore blockInput={inputEdit} />
      <PersonalFavoriteSport blockInput={inputEdit} />
    </form>
  )
}
